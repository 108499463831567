import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import DonationOption from "../components/Donate/DonationOption";
import {useNavigate} from "react-router-dom";

const Donate = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setOpen(true);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: 2,
        gap: 3,
      }}
    >
      <DonationOption
        name={"PayPal Giving Fund"}
        content={
          <Box>
            <Typography
              color={theme.palette.common.white}
              sx={{marginBottom: 2}}
            >
              Donate via the PayPal Giving Fund. When you donate using the
              PayPal Giving Fund, we do not pay any fees. You will also be given
              the choice to add Gift Aid once you have donated.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                window.open(
                  "https://www.paypal.com/gb/fundraiser/charity/4826330",
                  "_blank"
                );
              }}
              sx={{
                bgcolor: theme.palette.donate.primary,
                "&:hover": {
                  backgroundColor: theme.palette.donate.secondary,
                },
                color: theme.palette.common.black,
                width: "100%",
              }}
            >
              Donate via PayPal Giving Fund
            </Button>
          </Box>
        }
      />
      <DonationOption
        name={"Stripe"}
        content={
          <Box>
            <Typography
              color={theme.palette.common.white}
              sx={{marginBottom: 2}}
            >
              Stripe allows you to donate using any major credit/debit card and
              also with Apple/Google pay. However we do pay fees when you donate
              with Stripe. You will also be given the option to add Gift Aid
              which we will manually claim.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                window.open(
                  "https://donate.stripe.com/7sI9E0g5L0tx8Zq7ss",
                  "_blank"
                );
              }}
              sx={{
                bgcolor: theme.palette.donate.primary,
                "&:hover": {
                  backgroundColor: theme.palette.donate.secondary,
                },
                color: theme.palette.common.black,
                width: "100%",
              }}
            >
              Donate via Stripe
            </Button>
          </Box>
        }
      />
      <DonationOption
        name={"Bank Transfer"}
        content={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {[
              {
                id: "account-number",
                label: "Account Number",
                value: "68824095",
              },
              {id: "sort-code", label: "Sort Code", value: "08-92-50"},
              {id: "iban", label: "IBAN", value: "GB34CPBK08925068824095"},
              {id: "bic", label: "BIC", value: "CPBKGB21CCB"},
            ].map((option) => (
              <Box
                key={option.id}
                sx={{display: "flex", alignItems: "center", width: "100%"}}
              >
                <TextField
                  fullWidth
                  label={option.label}
                  defaultValue={option.value}
                  variant="standard"
                  readonly
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid white",
                    },
                    "& .MuiInput-underline:hover": {
                      borderBottom: "2px solid white",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottom: "2px solid white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "2px solid white",
                    },
                    paddingBottom: 1,
                  }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      style: {
                        color: theme.palette.common.white,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleCopy}
                            edge="end"
                            sx={{color: theme.palette.common.white}}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        }
      />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Copied"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default Donate;
