import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const AboutUsItem = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 500,
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      }}
    >
      <Typography
        variant="h4"
        color={theme.palette.primary.main}
        sx={{fontFamily: "cursive", paddingTop: 2, paddingLeft: 2}}
      >
        {props.name}
      </Typography>
      {props.content}
    </Box>
  );
};

export default AboutUsItem;
