import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Nav from "../components/Navigation/Nav";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import WhoWeAre from "../pages/WhoWeAre";
import Missions from "../pages/Missions";
import Events from "../pages/Events";
import Donate from "../pages/Donate";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Nav />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/donate" element={<Donate />} />
        {/* <Route path="/events" element={<Events />} />
        <Route path="/missions" element={<Missions />} /> */}
        <Route path="/who-we-are" element={<WhoWeAre />} />
      </Route>
    </>
  )
);

export default router;
