import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProjectCyrenaeus = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {xs: "column", md: "row"},
          alignItems: "center",
          maxWidth: 1000,
          padding: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            order: {xs: 2, md: 1},
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            color={theme.palette.common.white}
            sx={{fontFamily: "cursive", marginBottom: 2}}
          >
            Project Cyrenaeus
          </Typography>
          <Typography variant="h6" color={theme.palette.common.white}>
            The Cyrenaeus project aims to create a sustainable link between
            reconstructive surgical partners in Europe to surgical partners in
            Low and Middle-Income Countries (LMICs). Our current project is
            focused on establishing a link between the Plastic Surgery
            Departments of the UK West based large hospitals with St Mary's
            Lacor Hospital in North Uganda.
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            order: {xs: 1, md: 2},
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <video
            autoPlay
            loop
            muted
            controls
            style={{maxWidth: "250px", height: "auto"}}
          >
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/videos%2F0816.mov?alt=media&token=79283aae-8604-49b8-b170-fca818bbe149"
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCyrenaeus;
