import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {FaInstagram, FaFacebook, FaLinkedin, FaEnvelope} from "react-icons/fa";

const Socials = () => {
  const theme = useTheme();

  const style = {
    color: theme.palette.common.white,
    fontSize: 30,
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 2,
        gap: 2,
      }}
    >
      <IconButton
        onClick={() =>
          window.open("https://www.instagram.com/cyrenaeus", "_blank")
        }
      >
        <FaInstagram style={style} />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open(
            "https://www.facebook.com/profile.php?id=100093034806609",
            "_blank"
          )
        }
      >
        <FaFacebook style={style} />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open("https://www.linkedin.com/company/cyrenaeus/", "_blank")
        }
      >
        <FaLinkedin style={style} />
      </IconButton>
      <IconButton
        onClick={() => window.open("mailto:admin@cyrenaeus.com", "_blank")}
      >
        <FaEnvelope style={style} />
      </IconButton>
    </Box>
  );
};

export default Socials;
