import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const Welcome = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {xs: "column", md: "row"},
          alignItems: "center",
          maxWidth: 1000,
          padding: 2,
          gap: 2,
        }}
      >
        <Box sx={{flex: 1, order: {xs: 2, md: 1}, alignContent: "center"}}>
          <Typography
            variant="h3"
            color={theme.palette.common.white}
            sx={{fontFamily: "cursive"}}
          >
            Welcome...
          </Typography>
          <Typography color={theme.palette.common.white}>
            In Africa there are thousands of children and young adults affected
            by malformations, burns, trauma and cancer. They are in desperate
            need of plastic reconstructive surgery but no access to treatment.
            Cyrenaeus is a UK registered charity established with the main
            purpose of bringing teams of specialist clinicians to Africa in
            hospitals where plastic surgery is not available. We provide direct
            treatment to the patients but, most importantly, we aim to establish
            a long term sustainable collaboration with the hosting institutions
            to provide training to local doctors and nurses in essential basic
            plastic reconstructive surgery...
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: 2,
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/about");
              }}
              sx={{
                borderColor: theme.palette.common.white,
                color: theme.palette.common.white,
                padding: 1,
                flex: 1,
                maxWidth: 150,
              }}
            >
              Find out more
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/donate");
              }}
              sx={{
                bgcolor: theme.palette.donate.primary,
                "&:hover": {
                  backgroundColor: theme.palette.donate.secondary,
                },
                color: theme.palette.common.black,
                padding: 1,
                flex: 1,
                maxWidth: 150,
              }}
            >
              Donate
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            order: {xs: 1, md: 2},
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/cyrenaeus.jpg`}
            alt="My Image"
            style={{maxWidth: "100%", height: "auto"}}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
