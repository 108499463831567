import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DonationOption = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        alignContent: "center",
        borderColor: theme.palette.common.white,
        borderWidth: 2,
        borderRadius: 2,
        borderStyle: "solid",
        padding: 2,
        width: "100%",
        maxWidth: 600,
      }}
    >
      <Typography
        variant="h4"
        color={theme.palette.common.white}
        sx={{fontFamily: "cursive", marginBottom: 2}}
      >
        {props.name}
      </Typography>
      {props.content}
    </Box>
  );
};

export default DonationOption;
