import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

const News = () => {
  const theme = useTheme();

  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box sx={{padding: 2}}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 1200,
            textAlign: "left",
          }}
        >
          <Typography
            variant="h3"
            color={theme.palette.primary.main}
            sx={{fontFamily: "cursive"}}
          >
            News
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 2,
        }}
      >
        <Card sx={{maxWidth: 500}}>
          <CardActionArea
            onClick={() => {
              handleCardClick(
                "https://www.lacorhospital.org/how-surgeons-from-the-uk-gave-benjamin-a-second-chance-in-life/"
              );
            }}
          >
            <CardMedia
              sx={{height: 300}}
              image="https://www.lacorhospital.org/wp-content/uploads/2023/11/1-1024x640.jpg"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                How surgeons from the UK gave Benjamin a second chance in life
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              onClick={() => {
                handleCardClick(
                  "https://www.lacorhospital.org/how-surgeons-from-the-uk-gave-benjamin-a-second-chance-in-life/"
                );
              }}
              size="small"
            >
              Read Article
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};

export default News;
