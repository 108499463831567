import * as React from "react";
import PersistentDrawer from "./PersistentDrawer";

const Nav = () => {
  return (
    <div>
      <PersistentDrawer />
    </div>
  );
};

export default Nav;
