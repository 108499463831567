import Box from "@mui/material/Box";
import Welcome from "../components/Home/Welcome";
import OurMissions from "../components/Home/OurMissions";
import News from "../components/Home/News";

const Home = () => {
  return (
    <Box>
      <Welcome />
      <OurMissions />
      <News />
    </Box>
  );
};

export default Home;
