import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FoldedHandsIcon from "@mui/icons-material/Handshake";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Icon from "@mui/material/Icon";
import ProjectCyrenaeus from "../components/AboutUs/ProjectCyrenaeus";
import AboutUsItem from "../components/AboutUs/AboutUsItem";

const AboutUsListItem = (props) => {
  const theme = useTheme();

  return (
    <ListItem>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        primary={props.text}
        sx={{color: theme.palette.primary.main}}
      />
    </ListItem>
  );
};

const AboutUs = () => {
  const theme = useTheme();
  const needCol = "red";
  const valueCol = "green";

  return (
    <Box>
      <ProjectCyrenaeus />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <AboutUsItem
          name={"The Need"}
          content={
            <List>
              <AboutUsListItem
                text={
                  <>
                    The lack of global surgical infrastructure in LMICs has led
                    to huge morbidity, mortality, and financial burdens for
                    individuals and economies.<sup>1, 2</sup>
                  </>
                }
                icon={<FoldedHandsIcon sx={{color: needCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    Global Reconstructive Surgery is a fundamental component of
                    Global Surgery and refers to the provision of care for
                    patients with burns, hand trauma, soft tissue complex
                    wounds, skin cancer, sarcoma and congenital malformations.
                  </>
                }
                icon={<FoldedHandsIcon sx={{color: needCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    It has been shown that in LMICs the reconstructive surgical
                    burden has a higher ratio than that experienced in high
                    income countries and that represents at least 16% of the
                    total surgical burden.<sup>3, 4</sup>
                  </>
                }
                icon={<FoldedHandsIcon sx={{color: needCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    Reconstructive procedures for trauma and congenital
                    anomalies were identified as some of the 44 essential
                    surgical procedures that are highly cost effective by the
                    World Bank report in 2015.<sup>5</sup>
                  </>
                }
                icon={<FoldedHandsIcon sx={{color: needCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    Lacor hospital currently has 450 beds and 6 operating
                    theatres and provides mainly Maternity (8300 delivery/PA),
                    Accident and Emergency, Paediatric, General Surgery,
                    Orthopaedic.
                  </>
                }
                icon={<FoldedHandsIcon sx={{color: needCol}} />}
              />
            </List>
          }
        />
        <AboutUsItem
          name={"The Value"}
          content={
            <List>
              <AboutUsListItem
                text={
                  <>
                    Assist in furthering the current plastic surgery activity in
                    Lacor with training and infrastructure.
                  </>
                }
                icon={<VolunteerActivismIcon sx={{color: valueCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    Regular communication and support to help Lacor Hospital
                    develop their key aims within reconstructive surgery
                    provision.
                  </>
                }
                icon={<VolunteerActivismIcon sx={{color: valueCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    Establishment of a reciprocal trainees relationship where
                    training tools, education, mentorship and global surgical
                    understanding are shared.
                  </>
                }
                icon={<VolunteerActivismIcon sx={{color: valueCol}} />}
              />
              <AboutUsListItem
                text={
                  <>
                    We are aligned with ethical guidelines of practice as set
                    out by the Royal College of Surgeons and by the British
                    Foundation for International Reconstructive Surgery and
                    Training (BFIRST).<sup>6, 7</sup>
                  </>
                }
                icon={<VolunteerActivismIcon sx={{color: valueCol}} />}
              />
            </List>
          }
        />
        <AboutUsItem
          name={"References"}
          content={
            <List>
              <AboutUsListItem
                text={
                  "Meara, J. G., Hagander, L., & Leather, A. J. (2014). Surgery and global health: a Lancet Commission. The Lancet, 383(9911), 12-13."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    1
                  </Icon>
                }
              />
              <AboutUsListItem
                text={
                  "Roa, L., Jumbam, D. T., Makasa, E., & Meara, J. G. (2019). Global surgery and the sustainable development goals. Journal of British Surgery, 106(2), e44-e52."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    2
                  </Icon>
                }
              />
              <AboutUsListItem
                text={
                  "Goodacre, T. E. (1986). Plastic surgery in a rural African hospital: spectrum and implications. Annals of the Royal College of Surgeons of England, 68(1), 42."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    3
                  </Icon>
                }
              />
              <AboutUsListItem
                text={
                  "Jamison, D. T., Breman, J. G., Measham, A. R., Alleyne, G., Claeson, M., Evans, D. B., ... & Musgrove, P. (Eds.). (2006). Disease control priorities in developing countries."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    4
                  </Icon>
                }
              />
              <AboutUsListItem
                text={
                  "Debas, H. T., Donkor, P., Gawande, A., Jamison, D. T., Kruk, M. E., & Mock, C. N. (Eds.). (2015). Disease control priorities, (Volume 1): essential surgery. World Bank Publications."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    5
                  </Icon>
                }
              />
              <AboutUsListItem
                text={
                  "Royal College of Surgeons of England Guideline. Ethical Principles of Working Overseas."
                }
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    6
                  </Icon>
                }
              />
              <AboutUsListItem
                text={"BFIRST website, core values"}
                icon={
                  <Icon
                    sx={{color: theme.palette.primary.main, lineHeight: "1"}}
                  >
                    7
                  </Icon>
                }
              />
            </List>
          }
        />
      </Box>
    </Box>
  );
};

export default AboutUs;
