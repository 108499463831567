import {RouterProvider} from "react-router-dom";
import router from "./routes/Router";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useTheme} from "@mui/material/styles";

function App() {
  const defaultTheme = useTheme();

  const theme = createTheme({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        ...defaultTheme.palette.primary,
        main: "#063970",
      },
      donate: {
        primary: "#FFD700",
        secondary: "#daa520",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
