import React, {useEffect, useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useMediaQuery} from "@mui/material";

import {Outlet, useNavigate} from "react-router-dom";
import {charityName} from "../../util/Constants";
import {cutText} from "../../themes/CutText";
import Socials from "./Socials";
import ScrollToTop from "./ScrollToTop";

const drawerWidth = 240;

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, isMobile}) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: isMobile ? 0 : -drawerWidth,
    position: "relative",
    variants: [
      {
        props: ({open}) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginRight: 0,
        },
      },
    ],
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({theme, isMobile}) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({open}) => open,
      style: {
        width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: isMobile ? 0 : drawerWidth,
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [preloadedImage, setPreloadedImage] = useState("");

  useEffect(() => {
    const imageUrl = `${process.env.PUBLIC_URL}/images/colour.jpeg`;
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setPreloadedImage(imageUrl);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: "flex"}}>
      <AppBar
        position="fixed"
        open={open}
        isMobile={isMobile}
        sx={{bgcolor: "white"}}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              noWrap
              sx={preloadedImage ? cutText(preloadedImage) : {}}
              component="div"
            >
              {charityName}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/donate");
            }}
            sx={{
              bgcolor: theme.palette.donate.primary,
              "&:hover": {
                backgroundColor: theme.palette.donate.secondary,
              },
              color: theme.palette.common.black,
              marginRight: 2,
              fontSize: isMobile ? 10 : 15,
            }}
          >
            Donate
          </Button>
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={[open && {display: "none"}]}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main
        open={open}
        isMobile={isMobile}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: viewportHeight,
        }}
      >
        <Box>
          <DrawerHeader />
          <ScrollToTop />
          <Outlet />
        </Box>

        <Socials />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {title: "Home", link: "/"},
            {title: "About Us", link: "/about"},
            {title: "Who We Are", link: "/who-we-are"},
            // {title: "Missions", link: "/missions"},
            // {title: "Events", link: "/events"},
            // {title: "Benefactors And Sponsors", link: "/"},
            {title: "Donate", link: "/donate"},
          ].map((x, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(x.link);
                  setOpen(false);
                }}
              >
                <ListItemText primary={x.title.toUpperCase()} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
