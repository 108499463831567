export const cutText = (imageUrl) => ({
  display: "inline-block",
  color: "transparent",
  fontWeight: 700,
  fontFamily: "Inter, sans-serif",
  backgroundImage: `url(${imageUrl})`,
  backgroundClip: "text",
  backgroundSize: "100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  WebkitBackgroundClip: "text",
});
