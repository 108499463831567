import React from "react";
import {useTheme} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {members} from "../util/MockData";
import FlipCard from "../components/WhoWeAre/FlipCard";

const WhoWeAre = () => {
  const theme = useTheme();

  return (
    <Box>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h3"
            sx={{fontFamily: "cursive", color: theme.palette.primary.main}}
          >
            Advisory Board
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {members
              .filter((member) => member.roles.includes("Advisory Board"))
              .map((member) => {
                return <FlipCard {...member} />;
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h3"
            sx={{fontFamily: "cursive", color: theme.palette.primary.main}}
          >
            Clinicians
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {members
              .filter((member) => member.roles.includes("Clinician"))
              .map((member) => {
                return <FlipCard {...member} />;
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default WhoWeAre;
