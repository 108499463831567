import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MissionCard from "../Missions/MissionCard";
import {missions} from "../../util/MockData";

const OurMissions = () => {
  const theme = useTheme();

  return (
    <Box sx={{padding: 2}}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 1200,
            textAlign: "left",
          }}
        >
          <Typography
            variant="h3"
            color={theme.palette.primary.main}
            sx={{fontFamily: "cursive"}}
          >
            Our Missions
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {xs: "column", md: "row"},
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 2,
          gap: 2,
        }}
      >
        {missions.map((x) => {
          return <MissionCard {...x} />;
        })}
      </Box>
    </Box>
  );
};

export default OurMissions;
